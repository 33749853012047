import { Box, Image, Link, Text } from '@chakra-ui/react';
import ZeroLogo from '../static/logo/zero-logo.svg';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useGetUser } from '../store/app/hooks';
import ConnectWalletWithCaptcha from '../component/ConnectWalletWithCaptcha';

export default function AppHeader() {
  const user = useGetUser();

  return (
    <Box position={'fixed'} top={{ base: 30, md: 50 }} w={'100vw'} zIndex={999}>
      <Box
        h={{ base: '72px', md: 84 }}
        maxW={{ base: 'calc(100vw - 32px)', md: 1040 }}
        borderRadius={{ base: 37, md: 42 }}
        p={4}
        mx={'auto'}
        className={'theme-box-shadow'}
        w={'100%'}
        backdropFilter={'blur(50px)'}
        bgColor={'#00000040'}
      >
        <Box
          height={'100%'}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Box
            display={'flex'}
            justifyContent={'flex-start'}
            alignItems={'baseline'}
          >
            <Link href={'/'}>
              <Image
                src={ZeroLogo}
                alt={'Zerolend-logo'}
                h={{ base: '26px', md: 'auto' }}
              />
            </Link>
          </Box>
          <Box display={{ base: 'none', md: 'flex' }}>
            <Link
              href={'/#/tasks'}
              _hover={{ textDecoration: 'none', opacity: 0.8 }}
              px={4}
            >
              <Text fontSize={14}>Tasks</Text>
            </Link>
            <Link
              href={'/#/leaderboard'}
              _hover={{ textDecoration: 'none', opacity: 0.8 }}
              px={4}
            >
              <Text fontSize={14}>Leaderboard</Text>
            </Link>
          </Box>
          {user === undefined ? (
            <ConnectWalletWithCaptcha />
          ) : (
            <ConnectButton showBalance={false} chainStatus={'icon'} />
          )}
        </Box>
      </Box>
    </Box>
  );
}
