import { ReactNode } from 'react';
import AppHeader from './AppHeader';
import { Box, useMediaQuery } from '@chakra-ui/react';
import '../static/styles/layout.css';
import MoAppNavigation from './MoAppNavigation';

interface IProps {
  isHomePage?: boolean;
  children: ReactNode;
}

export function MainLayout(props: IProps) {
  const { children, isHomePage } = props;
  const [isSmallerThan450] = useMediaQuery('(max-width: 450px)');
  return (
    <Box
      flexDirection={'column'}
      position={'relative'}
      display={'flex'}
      flex={1}
      minH={'100vh'}
      height={'fit-content'}
      className={'bg-image'}
    >
      {!isHomePage && <AppHeader />}
      {children}
      {!isHomePage && isSmallerThan450 && <MoAppNavigation />}
    </Box>
  );
}
