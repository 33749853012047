import CustomModal from "./index";
import {Box} from "@chakra-ui/react";
import ReCAPTCHA from "react-google-recaptcha";
import {useConnectModal} from "@rainbow-me/rainbowkit";
import {useGetCaptchaModalStatus} from "../store/app/hooks";
import store from "../store";
import {toggleCaptchaModal} from "../store/app/actions";

const CaptchaModal = () => {
  const isOpen = useGetCaptchaModalStatus();
  const {openConnectModal} = useConnectModal();

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={() => store.dispatch(toggleCaptchaModal())}
      modalContentProps={{background: 'transparent'}}
    >
      <Box pos={'relative'}>
        <ReCAPTCHA
          sitekey="6LcI1VYpAAAAAFxxkezIKs2f5uOVK8PFaxOOIsVR"
          onChange={(data) => {
            if (data && openConnectModal) {
              localStorage.setItem('captcha', data);
              openConnectModal();
              store.dispatch(toggleCaptchaModal());
            }
          }}
          onErrored={() => console.log('Recaptcha error')}
          onExpired={() => console.log('Recaptcha expired')}
          size={'normal'}
          theme={'dark'}
        />
      </Box>
    </CustomModal>
  )
}

export default CaptchaModal;