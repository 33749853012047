import { process_env } from './env';
import { Link } from '@chakra-ui/react';

export const basePath = process_env.REACT_APP_SERVER_API;

export type ITaskId =
  | 'AAVEStaker'
  | 'borrow'
  | 'discordFollow'
  | 'gm'
  | 'liquidityONEZ'
  | 'LQTYHolder'
  | 'LUSDHolder'
  | 'mintingONEZ'
  | 'referral'
  | 'supply'
  | 'twitterFollow'
  | 'PythStaker'
  | 'MantaStaker'
  | 'supplyBlast'
  | 'CakeStaker'
  | 'MahaXStaker'
  | 'borrowBlast'
  | 'supplyLinea'
  | 'borrowLinea'
  | 'supplyXLayer'
  | 'borrowXLayer'
  | 'supplyEthereumLrt'
  | 'borrowEthereumLrt'
  | 'supplyEthereumLrtEth'
  | 'supplyZkSyncCake';

export type ITaskType = 'automated' | 'manual-one-time' | 'unique';

export interface IDetails {
  taskId: ITaskId;
  taskType: ITaskType;
  descToolTip?: string;
  pointsToolTip?: string;
  title: string;
  desc: React.ReactNode;
  points: string;
  verifyLink: string;
  taskLink?: string;
  buttonText?: string;
  status: 'upcoming' | 'closed' | 'live' | 'stag';
  pointsUpdateText?: string;
}

export const twitterVerifyLink = `${basePath}/twitter/redirect?jwt=`;
export const discordVerifyLink = `${basePath}/discord/redirect?jwt=`;

export const Tasks: IDetails[] = [
  {
    taskId: 'discordFollow',
    title: 'Join our Discord',
    desc: 'Join our Discord and verify yourself to claim this task.',
    descToolTip:
      'You must join our discord and verify yourself to claim this task.',
    points: '100 PTS',
    verifyLink: discordVerifyLink,
    taskLink: `https://discord.gg/zerolend`,
    taskType: 'unique',
    status: 'live',
  },
  {
    taskId: 'gm',
    title: 'Say GM in Discord ☀️',
    desc: 'Head to the #gm channel on our discord and say GM to get daily points.',
    points: '10 PTS per gm/day',
    pointsToolTip: 'Claimable only once every day',
    verifyLink: ``,
    taskLink: `https://discord.com/channels/1130461388486221925/1130836311113224273`,
    buttonText: 'Say GM',
    taskType: 'automated',
    status: 'live',
  },
  {
    taskId: 'supply',
    title: 'Supply collateral to the lending markets',
    desc: 'Provide at least $100 and earn daily points.',
    descToolTip:
      'Points start accumulating alongside your earlyZERO rewards. Points are not backdated. This Quest includes points from Manta, Linea, Zksync, Blast, Ethereum LRTs, XLayer Market',
    points: '1 PTS per USD/day',
    pointsToolTip:
      'For example, if you supply $1000 worth of ETH into Manta, you will earn 1000 PTS per day from the Manta market.',
    verifyLink: ``,
    taskLink: `https://app.zerolend.xyz`,
    buttonText: 'Supply Liquidity',
    taskType: 'automated',
    status: 'live',
    pointsUpdateText: 'Updates every 24hrs',
  },
  {
    taskId: 'borrow',
    title: 'Borrow assets from the lending markets',
    pointsToolTip:
      'Example: If you borrow 100$ worth of USDT from the lending protocol, you will earn 400 PTS per day',
    desc: 'Borrow assets from the lending protocol to earn points.',
    descToolTip:
      'Points start accumulating alongside your earlyZERO rewards. Points are not backdated. This Quest includes points from Manta, Linea, ZkSync, Blast, Ethereum LRTs and XLayer Market.',
    points: '4 PTS per USD/day',
    verifyLink: ``,
    taskLink: `https://app.zerolend.xyz`,
    buttonText: 'Borrow Liquidity',
    taskType: 'automated',
    status: 'live',
    pointsUpdateText: 'Updates every 24hrs',
  },
  /*{
    taskId: 'supplyBlast',
    title: 'Supply collateral to the Blast Market',
    desc: 'Provide at least $100 and earn daily points. Points start accumulating from the',
    descToolTip:
      'Points start accumulating alongside your earlyZERO rewards. Points are not backdated',
    points: '1 PTS per USD/day',
    pointsToolTip:
      'Example: If you supply 1000$ worth of ETH into Blast market then you will earn 1000 PTS per day for Blast market',
    verifyLink: ``,
    taskLink: `https://app.zerolend.xyz/?marketName=proto_blast_v3`,
    buttonText: 'Supply Liquidity',
    taskType: 'automated',
    status: 'live',
    pointsUpdateText: 'Updates every 24hrs',
  },
  {
    taskId: 'borrowBlast',
    title: 'Borrow assets from Blast Market',
    pointsToolTip:
      'Example: If you borrow 100$ worth of USDT from the lending protocol on Blast market, you will earn 400 PTS per day',
    desc: 'Borrow assets from the lending protocol on Blast Market to earn points',
    descToolTip:
      'Points start accumulating alongside your earlyZERO rewards. Points are not backdated',
    points: '4 PTS per USD/day',
    verifyLink: ``,
    taskLink: `https://app.zerolend.xyz/?marketName=proto_blast_v3`,
    buttonText: 'Borrow Liquidity',
    taskType: 'automated',
    status: 'live',
    pointsUpdateText: 'Updates every 24hrs',
  },*/
  {
    taskId: 'supplyEthereumLrtEth',
    title: 'Supply ETH on the Ethereum LRTs Market (+2x ETH Boost)',
    desc: 'Supply ETH on the Ethereum market to earn the highest supply points from Zero Gravity.',
    descToolTip:
      'Points start accumulating alongside your earlyZERO rewards. Points are not backdated.',
    points: '2 PTS per USD/day',
    pointsToolTip:
      'For example, if you supply $1000 worth of ETH into the Ethereum market, you will earn an additional 2000 PTS per day, plus 1000 points for completing the supply LP quest.',
    verifyLink: ``,
    taskLink: `https://app.zerolend.xyz/?marketName=proto_mainnet_lrt_v3`,
    buttonText: 'Supply Liquidity',
    taskType: 'automated',
    status: 'live',
    pointsUpdateText: 'Updates every 24hrs',
  },
  {
    taskId: 'referral',
    title: 'Earn by referring a user',
    desc: 'Refer and earn 20% bonus points.',
    pointsToolTip:
      "For example, if you refer someone who earns 100 points from a task, they'll receive a 20% bonus, totaling 120 points. You will also earn a 20% reward, which is 20 points.",
    points: '20% Referral Bonus',
    verifyLink: ``,
    buttonText: 'Refer',
    taskType: 'automated',
    status: 'live',
  },
  {
    taskId: 'PythStaker',
    title: 'Are you a $PYTH staker?',
    desc: 'If you are a PYTH staker, you will receive a $ZERO airdrop based on $PYTH staked.',
    points: '1 PTS per $PYTH Staked',
    pointsToolTip:
      'Eg. If you stake 1000 $PYTH, then you will receive 1000 PTS.',
    verifyLink: ``,
    buttonText: 'Check Eligibility',
    taskType: 'manual-one-time',
    status: 'live',
  },
  {
    taskId: 'MantaStaker',
    title: 'Are you a $MANTA staker?',
    desc: 'If you are a MANTA staker, you will receive a $ZERO airdrop based on $MANTA staked.',
    points: '5 PTS per $MANTA Staked',
    pointsToolTip:
      'Eg. If you stake 1000 $MANTA, then you will receive 5000 PTS',
    verifyLink: ``,
    buttonText: 'Check Eligibility',
    taskType: 'manual-one-time',
    status: 'live',
  },
  {
    taskId: 'CakeStaker',
    title: 'Are you a VeCake holder? ',
    desc: (
      <span>
        If you stake $CAKE (PancakeSwap) for veCAKE, you will receive a $ZERO
        airdrop based on $CAKE staked.
      </span>
    ),
    points: '0.25 PTS per $CAKE staked',
    verifyLink: ``,
    buttonText: 'Check Eligibility',
    taskType: 'manual-one-time',
    status: 'live',
  },
  {
    taskId: 'MahaXStaker',
    title: 'Are you a MAHAX holder? ',
    desc: (
      <span>
        If you stake $MAHA for MAHAX, you will receive a
        <br /> $ZERO airdrop based on $MAHA staked. Stake MAHA{' '}
        <Link
          href={'https://gov.mahadao.com/#/locker'}
          target={'_blank'}
          textDecoration={'underline'}
        >
          here
        </Link>
      </span>
    ),
    points: '1000 PTS per MAHAX value',
    pointsToolTip:
      'Eg. If you stake 1000 $MAHA and have 999 MAHAX, then you will receive 999000 PTS',
    verifyLink: ``,
    buttonText: 'Check Eligibility',
    taskType: 'manual-one-time',
    status: 'live',
  },
  /*{
    taskId: 'twitterFollow',
    title: 'Follow on Twitter',
    desc: 'Follow @zerolendxyz on Twitter.',
    points: '100 PTS',
    verifyLink: `${twitterVerifyLink}`,
    taskLink: `https://twitter.com/intent/follow?screen_name=zerolendxyz`,
    taskType: 'unique',
    status: 'upcoming',
  },
  {
    taskId: 'AAVEStaker',
    title: 'Are you an AAVE user/staker?',
    desc: 'Get points for having used or staked AAVE',
    points: '10,000 PTS',
    verifyLink: ``,
    taskLink: `https://aave.com`,
    buttonText: 'Check Eligibility',
    taskType: 'manual-one-time',
    status: 'upcoming',
  },
  {
    taskId: 'LQTYHolder',
    title: 'Are you a Liquity user/staker?',
    desc: 'Get points for having used or staked LQTY/LUSD',
    points: '10,000 PTS',
    verifyLink: ``,
    taskLink: `https://www.liquity.org/`,
    buttonText: 'Check Eligibility',
    taskType: 'manual-one-time',
    status: 'upcoming',
  },*/
  // {
  //   taskId: 'RDNTStaker',
  //   title: 'Are you a RDNT user?',
  //   desc: 'Get points for having used or staked RDNT',
  //   points: '10,000 PTS',
  //   verifyLink: ``,
  //   taskLink: `https://radiant.capital/`,
  //   buttonText: 'Check Eligibility',
  //   taskType: 'manual-one-time',
  //   status: 'upcoming',
  // },
  /*{
    taskId: 'mintingONEZ',
    title: 'Mint ONEZ',
    desc: 'Mint ONEZ and one point for every dollar supplied per day',
    points: '1 PTS per USD/day',
    verifyLink: ``,
    taskLink: `https://onez.cash`,
    buttonText: 'Mint ONEZ',
    taskType: 'automated',
  },
  {
    taskId: 'liquidityONEZ',
    title: 'Supply ONEZ liquidity',
    descToolTip: 'Supply ONEZ to the stability pool or LPs.',
    pointsToolTip:
      'Example: If you provide 100$ worth of liquidity to ONEZ, you will get 100 PTS a day',
    desc: 'Supply ONEZ to the stability pool or LPs',
    points: '1 PTS per USD/day',
    verifyLink: ``,
    taskLink: `https://onez.cash`,
    buttonText: 'Supply Liquidity',
    taskType: 'automated',
  },*/
];
