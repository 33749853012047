import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { logout, updateUser, toggleCaptchaModal } from './actions';
import { SiweMessage } from 'siwe';
import {
  getLeaderBoard,
  getUser,
  getUserJwtToken,
} from '../../utils/ServerApi';
import { HttpError } from '../../utils/apiCaller';

export interface IUserPoints {
  AAVEStaker?: number;
  borrow?: number;
  discordFollow?: number;
  gm?: number;
  liquidityONEZ?: number;
  LQTYHolder?: number;
  LUSDHolder?: number;
  MahaXStaker?: number;
  mintingONEZ?: number;
  referral?: number;
  supply?: number;
  twitterFollow?: number;
  PythStaker?: number;
  MantaStaker?: number;
  CakeStaker?: number;
  supplyZkSyncCake?: number;
  supplyBlast?: number;
  borrowBlast?: number;
  supplyLinea?: number;
  borrowLinea?: number;
  supplyEthereumLrt?: number;
  borrowEthereumLrt?: number;
  supplyEthereumLrtEth?: number;
  supplyXLayer?: number;
  borrowXLayer?: number;
}

export interface IUserCheck {
  gm: boolean;
  AAVEStaker: boolean;
  LQTYHolder: boolean;
  LUSDHolder: boolean;
  MahaXStaker: boolean;
  AAVEStakers: boolean;
  CakeStaker: boolean;
  discordFollow: boolean;
  discordVerify: boolean;
  twitterVerify: boolean;
  twitterFollow: boolean;
  PythStaker: boolean;
}
export interface IUser {
  discordId: string;
  twitterId: string;
  referralCode: string;
  jwt: string;
  rank: number;
  walletAddress: string;
  totalPointsV2: number;
  totalPoints: number;
  twitterOauthToken: string;
  twitterOauthTokenSecret: string;

  discordFollowChecked: boolean;
  discordVerify: boolean;
  twitterFollowChecked: boolean;
  twitterVerify: boolean;
  gmChecked: boolean;
  AAVEStakerChecked: boolean;
  LQTYHolderChecked: boolean;
  LUSDHolderChecked: boolean;
  gmPoints: number;
  mintingONEZPoints: number;
  liquidityONEZPoints: number;
  discordFollowPoints: number;
  twitterFollowPoints: number;
  LQTYHolderPoints: number;
  LUSDHolderPoints: number;
  AAVEStakerPoints: number;

  checked?: IUserCheck;
  points?: IUserPoints;
}

export const loginThunk = createAsyncThunk(
  'app/fetchJwt',
  (data: {
    message: SiweMessage;
    signature: string;
    referredByCode?: string;
    captcha: string | null;
  }) =>
    getUserJwtToken(data)
      .then((response) => response.user)
      .catch((error) => console.log('error getUserJwtToken', error)),
);

export const fetchLeaderBoard = createAsyncThunk(
  'app/fetchLeaderBoard',
  (data: { pageNo: number; pageSize: number }) =>
    getLeaderBoard(data)
      .then((response) => {
        return { data: response };
      })
      .catch((error) => console.log('error fetchLeaderBoard', error)),
);

export const userThunk = createAsyncThunk('app/user', (data: { jwt: string }) =>
  getUser(data.jwt)
    .then((response) => response.user)
    .catch((error: HttpError) => {
      if (error.status === 401) {
        return {};
      }
    })
    .finally(() => {}),
);

interface State {
  user?: IUser;
  leaderBoardData: { data: [] };
  isCaptchaOpen: boolean;
}

export const initialState: State = {
  leaderBoardData: { data: [] },
  isCaptchaOpen: false,
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(logout, (state) => {
      delete state.user;
      return state;
    })
    .addCase(updateUser, (state, action) => {
      state.user = { ...action.payload };
      return state;
    })
    .addCase(fetchLeaderBoard.fulfilled, (state, action) => {
      if (action.payload) state.leaderBoardData = action.payload;
    })
    .addCase(loginThunk.fulfilled, (state, action) => {
      if (action.payload) state.user = { ...action.payload };
      return state;
    })
    .addCase(userThunk.fulfilled, (state, action) => {
      if (action.payload) state.user = { ...action.payload };
      return state;
    })
    .addCase(toggleCaptchaModal, (state) => {
      console.log('state', state.isCaptchaOpen);
      state.isCaptchaOpen = !state.isCaptchaOpen;
      return state;
    }),
);
