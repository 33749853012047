import apiCaller from './apiCaller';
import { IUser } from '../store/app/reducer';
import { SiweMessage } from 'siwe';
import { ITaskId } from '../config';

export const checkServer = async (): Promise<any> => {
  return await apiCaller(``, 'get');
};

export const getUserJwtToken = async (body: {
  message: SiweMessage;
  signature: string;
}): Promise<any> => {
  return await apiCaller<{ user: IUser }>('user/login', 'post', body);
};

export const getUser = async (jwt: string): Promise<any> => {
  return await apiCaller<{ user: IUser }>('user/me', 'get', null, jwt);
};

export const userDiscordLogin = async (jwt: string): Promise<any> => {
  return await apiCaller(`discord/login`, 'get', null, jwt);
};

export const getLeaderBoard = async (data: {
  pageNo: number;
  pageSize: number;
}): Promise<any> => {
  return await apiCaller(`user/lb`, 'get', null);
};

export const getTaskCheck = async (
  body: { taskId: ITaskId },
  jwt: string,
): Promise<{ user: IUser }> => {
  return await apiCaller<{ user: IUser }>('user/check', 'post', body, jwt);
};

export const registerDiscord = async (
  code: string,
  jwt: string,
): Promise<{ user: IUser }> => {
  return await apiCaller(`discord/register`, 'post', { code }, jwt);
};

export const registerTwitter = async (
  body: { oauth_token: string; oauth_verifier: string },
  jwt: string,
): Promise<IUser> => {
  return await apiCaller(`twitter/register`, 'post', body, jwt);
};
