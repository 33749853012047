import { darkTheme, Theme } from '@rainbow-me/rainbowkit';
export const myCustomTheme: Theme = {
  // lightMode: {
  ...darkTheme(),
  colors: {
    ...darkTheme().colors,
    accentColor: '#fff',
    accentColorForeground: '#000',
  },
  radii: {
    ...darkTheme().radii,
    connectButton: '24px',
  },
  // },
  /*darkMode: {
    ...darkTheme(),
    colors: {
      ...darkTheme().colors,
      accentColor: "#7A54FF",
    },
  },*/
};
