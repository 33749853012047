import { MainLayout } from '../layouts/MainLayout';
import Home from '../modules/home';

export default function HomePage() {
  return (
    <MainLayout isHomePage={true}>
      <Home />
    </MainLayout>
  );
}
