import { createAuthenticationAdapter } from '@rainbow-me/rainbowkit';
import { IUser, loginThunk } from '../store/app/reducer';
import { logout } from '../store/app/actions';
import { SiweMessage } from 'siwe';
import store from '../store';
import { process_env } from '../env';

const authenticationAdapter = createAuthenticationAdapter({
  getNonce: async () => {
    return Math.floor(Date.now() / 1000).toString();
  },

  createMessage: ({ nonce, address, chainId }) => {
    return new SiweMessage({
      domain: window.location.host,
      address,
      statement:
        'You are signing this message to verify your ownership with the ZeroLend Airdrop platform.' +
        ' This signature is unique and does not grant any special permissions to any of the assets in your wallet.',
      uri: window.location.origin,
      version: '1',
      chainId,
      nonce,
    });
  },

  getMessageBody: ({ message }) => {
    return message.prepareMessage();
  },

  verify: async ({ message, signature }) => {
    const referralCode = localStorage.getItem('invite');
    const captcha = localStorage.getItem('captcha');
    if (captcha === null && process_env.REACT_APP_ENABLE_CAPTCHA) return false;
    const action = await store.dispatch(
      loginThunk({
        message,
        signature,
        referredByCode: referralCode ? referralCode : undefined,
        captcha: captcha,
      }),
    );
    const payload = action.payload as IUser;
    return payload.walletAddress === message.address?.toLowerCase();
  },

  signOut: async () => {
    console.log('signOut');
    store.dispatch(logout());
  },
});

export default authenticationAdapter;
