import axios, { AxiosError } from 'axios';
import { basePath } from '../config';

interface IHttpError {
  success: false;
  errorName: 'Error';
  error: string;
}

export class HttpError extends Error {
  status: number = 500;

  constructor(message?: string, status?: number) {
    super(message);
    if (status) this.status = status;
  }
}

async function apiCaller<T>(
  endpoint: string,
  method: string = 'get',
  body?: any,
  jwt?: string,
): Promise<T> {
  const url = `${basePath}/${endpoint}`;
  const headers: any = {
    'content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  };

  if (jwt) headers['x-jwt'] = jwt;
  try {
    const ret = await axios<T | IHttpError>({
      url,
      headers,
      method,
      data: body,
    });
    const err = ret.data as IHttpError;
    if (ret.status !== 200) throw new HttpError(err.error, ret.status);
    return ret.data as T;
  } catch (e: any) {
    const err = e as AxiosError<any>;
    throw new HttpError(
      err.response?.data.message || err.message,
      err.response?.status || 500,
    );
  }
}

export default apiCaller;
