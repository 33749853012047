import {Box, Image, Link, Text} from '@chakra-ui/react';
import Container from '../../layouts/Container';
// import ZeroLogo from '../../static/logo/zero-logo.svg';
import Zeroticker from '../../static/logo/zero-ticker.png';
import Button from '../../component/Button';
// import { useGetLeaderboard } from '../../store/app/hooks';
import TGECountdown from './component/TGECountdown';
import {useState} from "react";
import {FaArrowRightLong} from "react-icons/fa6";

const Home = () => {
  // const leaderData = useGetLeaderboard();

  const [dontShowCountDown, setDontShowCountDown] = useState(false);

  return (
    <Box minH={{base: 'auto', md: '100vh'}} position={'relative'}>
      <Container>
        <Box width={'100%'} mb={{base: 16, md: 24}}>
          <Box
            maxW={{base: 'auto', md: '798px'}}
            pt={{base: 16, md: 24}}
            mx={'auto'}
            textAlign={{base: 'center', md: 'center'}}
            zIndex={-1}
          >
            <Link href={'https://zerolend.xyz'} target={'_blank'}>
              <Image
                src={Zeroticker}
                alt={'Zerolend-logo'}
                mx={{base: 'auto', md: 'auto'}}
                mb={{base: 12, md: 16}}
                borderRadius={'50%'}
                width={{base: '80px', md: '150px'}}
              />
            </Link>
            <Text
              fontWeight={600}
              fontFamily={'body'}
              fontSize={{base: 42, md: 84}}
              lineHeight={{base: '110%', md: '140%'}}
              className={'black-gradient-text'}
              mb={{base: 4, md: 8}}
            >
              <span className={'black-gradient-text'}>Claim $ZERO</span> <br/>
              {/*ZERO TO ZILLION*/}
            </Text>
            <Text
              fontFamily={'body'}
              fontSize={{base: '14px', md: '22px'}}
              lineHeight={{base: '110%', md: '120%'}}
              mb={{base: 12, md: 16}}
              opacity={0.6}
            >
              Zero Gravity points and earlyZERO will be available to claim and stake on <Link
              href={'https://app.zerolend.xyz'} target={'_blank'}>app.zerolend.xyz</Link> on May 06,
              2024.
            </Text>
            {/*<Link href={'/#/voyage-1/chapter-1/quests'}>
              <Button
                minW={{base: '136px', md: '180px'}}
                variant={'outline'}
                px={4}
                disabled={true}
              >
                Claim $ZERO&nbsp;
                <FaArrowRightLong/>
              </Button>
            </Link>*/}
            {dontShowCountDown
              ? <Link href={'https://app.zerolend.xyz/rewards/?marketName=proto_linea_v3'}><Button
                minW={{base: '136px', md: '180px'}}
                variant={'outline'}
                px={4}
              >
                Claim $ZERO Airdrop&nbsp;
                <FaArrowRightLong/>
              </Button>
              </Link>
              : <Button
                minW={{base: '136px', md: '180px'}}
                variant={'outline'}
                px={4}
                disabled={true}
              >
                Claiming will open up soon
                {/*View $ZERO Airdrop&nbsp;*/}
                {/*<FaArrowRightLong/>*/}
              </Button>}
          </Box>
        </Box>
        <Box>
          {!dontShowCountDown && <TGECountdown onComplete={setDontShowCountDown}/>}
        </Box>
        <Box w={'100%'} position={'relative'}>
          {/*<Image
            src={zerosm}
            mx={'auto'}
            mb={{ base: '-50px', md: '-100px' }}
            zIndex={-1}
          />*/}
          {/*<Text
            mx={'auto'}
            textAlign={'center'}
            mb={{ base: '-50px', md: '-70px' }}
            zIndex={-1}
            fontSize={{ base: '24px', md: '140px' }}
            color={'#FFFFFF20'}
          >
            ZERO TO ZILLION
          </Text>*/}
          {/*{leaderData.length > 0 && (
            <Box
              w={'100%'}
              padding={{ base: 6, md: 8 }}
              border={'1px solid #FFFFFF1A'}
              borderRadius={'2xl'}
              bgColor={'black'}
              bgImage={deepwaves}
              backgroundPosition={'0, -300px'}
              backgroundSize={'cover'}
              zIndex={2}
              pos={'relative'}
              borderBottomWidth={0}
            >
              <Box
                display={{ base: 'block', md: 'flex' }}
                justifyContent={'space-between'}
                alignItems={'center'}
                mb={{ base: 8, md: 12 }}
              >
                <Text fontSize={'28px'} mb={{ base: 2, md: 0 }}>
                  Leaderboard
                </Text>
                <Link fontSize={'16px'} href={'/#/leaderboard'}>
                  <HStack>
                    <Text>View leaderboard </Text>
                    <FaArrowRightLong />
                  </HStack>
                </Link>
              </Box>
              <Box>
                <LeaderBoardTable isHomePage={true} />
              </Box>
            </Box>
          )}*/}
        </Box>
      </Container>
    </Box>
  );
};

export default Home;
