type IModes = 'prod' | 'stag' | 'dev';

const mode: IModes = process.env.REACT_APP_MODE as IModes;

interface IEnv {
  REACT_APP_SERVER_API: string;
  REACT_APP_SENTRY_KEY: string;
  REACT_APP_ENABLE_CAPTCHA: boolean;
  REACT_APP_MODE: string;
}

let envs: { [key in IModes]: IEnv } = {
  prod: {
    REACT_APP_SERVER_API: 'https://airdrop-api-aws.zerolend.xyz', //DO NOT TOUCH
    REACT_APP_SENTRY_KEY:
      'https://eae5a84d6cea93f3e2b7403bfd68dab1@o4506613932163072.ingest.sentry.io/4506614125625344', //DO NOT TOUCH
    REACT_APP_ENABLE_CAPTCHA: true,
    REACT_APP_MODE: 'prod',
  },
  stag: {
    REACT_APP_SERVER_API: 'https://staging-api-airdrop.zerolend.cloud',
    REACT_APP_SENTRY_KEY: '',
    REACT_APP_ENABLE_CAPTCHA: false,
    REACT_APP_MODE: 'stag',
  },
  dev: {
    REACT_APP_SERVER_API: 'https://7369-111-125-237-95.ngrok-free.app',
    REACT_APP_SENTRY_KEY: '',
    REACT_APP_ENABLE_CAPTCHA: false,
    REACT_APP_MODE: 'dev',
  },
};
export const process_env = envs[mode] || envs['prod'];
