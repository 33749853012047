import { configureStore } from '@reduxjs/toolkit';
import { load, save } from 'redux-localstorage-simple';

import app from './app/reducer';

const store = configureStore({
  reducer: {
    app,
  },

  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(save());
  },

  preloadedState: load(),
});

export default store;

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
