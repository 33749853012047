import { Button, ButtonProps } from '@chakra-ui/react';

export interface ICButtonProps extends ButtonProps {
  variant?: 'taskButton' | 'outline';
}

const CButton = (props: ICButtonProps) => {
  const { variant = 'filled' } = props;

  if (variant === 'outline') {
    return (
      <Button
        {...props}
        // variant={'outline'}
        outline={'none'}
        height={{ base: '42px', md: '52px' }}
        borderRadius={{ base: '21px', md: '26px' }}
        color={'white'}
        _hover={{
          bgColor: 'white',
          color: 'black',
        }}
      >
        {props.children}
      </Button>
    );
  } else if (variant === 'taskButton') {
    return (
      <Button
        {...props}
        height={{ base: '42px', md: '42px' }}
        borderRadius={{ base: '21', md: '21px' }}
        bgGradient={`linear-gradient(209.28deg, rgba(255, 255, 255, 0.28) -21.5%, rgba(255, 255, 255, 0.03) 29.96%),
          linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1))`}
        color={'#FFFFFF'}
        className={'theme-box-shadow'}
        minW={'180px'}
        fontSize={'14px'}
        outline={'none'}
        _hover={{
          opacity: 0.6,
        }}
      >
        {props.children}
      </Button>
    );
  } else {
    return (
      <Button
        {...props}
        outline={'none'}
        height={{ base: '42px', md: '52px' }}
        borderRadius={{ base: '21px', md: '26px' }}
        border={'1px solid white'}
        _hover={{
          bgColor: 'transparent',
          color: 'white',
        }}
      >
        {props.children}
      </Button>
    );
  }
};

export default CButton;
