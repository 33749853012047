import { Box, Link } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

export default function MoAppNavigation() {
  const location = useLocation();

  const isLeaderboard = location.pathname.includes('leaderboard');
  const isTask =
    location.pathname.includes('task') || location.pathname.includes('quests');

  return (
    <Box position={'fixed'} bottom={'12px'} w={'100vw'} zIndex={999}>
      <Box
        maxW={'calc(100vw - 32px)'}
        borderRadius={42}
        p={3}
        mx={'auto'}
        w={'100%'}
        backdropFilter={'blur(200px)'}
        bgColor={'#00000040'}
        border={'1px solid #FFFFFF40'}
      >
        <Box
          height={'100%'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Box
            flex={0.5}
            textAlign={'center'}
            border={isLeaderboard ? '1px solid #FFFFFF40' : 'none'}
            py={2}
            borderRadius={'24px'}
          >
            <Link href={'/#/leaderboard'}>Leaderboard</Link>
          </Box>
          <Box
            flex={0.5}
            textAlign={'center'}
            border={isTask ? '1px solid #FFFFFF40' : 'none'}
            py={2}
            borderRadius={'24px'}
          >
            <Link href={'/#/tasks'}>Quests</Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
