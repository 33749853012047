import React, { ReactNode } from 'react';

import '../App.css';
import '../static/fonts/pixeboy-font/pixeboy.css';
import '@rainbow-me/rainbowkit/styles.css';

import { ChakraProvider } from '@chakra-ui/react';
import {
  coinbaseWallet,
  injectedWallet,
  rabbyWallet,
  frameWallet,
  rainbowWallet,
  walletConnectWallet,
  metaMaskWallet,
  ledgerWallet,
  safeWallet,
  phantomWallet,
  okxWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import {
  connectorsForWallets,
  RainbowKitAuthenticationProvider,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { customTheme } from '../static/ChakraTheme';
import { HashRouter as Router } from 'react-router-dom';
import { myCustomTheme } from '../static/RainbowTheme';
import { Provider } from 'react-redux';
import { publicProvider } from 'wagmi/providers/public';
import { ToastProviderProps } from '@chakra-ui/toast';
import { useUserAuthenticated } from '../store/app/hooks';
import authenticationAdapter from '../protocol/authenticationAdapter';
import Navigation from '../navigation';
import store from '../store';
import { useGetAccount } from '../hooks/useWagmiHooks';
import { RainbowKitChain } from '@rainbow-me/rainbowkit/dist/components/RainbowKitProvider/RainbowKitChainContext';
import { zkSync, mainnet, manta, linea } from 'viem/chains';
import { ParticleNetwork } from '@particle-network/auth';
import { particleWallet } from '@particle-network/rainbowkit-ext';
// import Updaters from '../component/updaters';
import CaptchaModal from '../modals/CaptchaModal';

const ToastOptions: ToastProviderProps = {
  defaultOptions: {
    position: 'top-right',
    isClosable: true,
    variant: 'left-accent',
  },
};

new ParticleNetwork({
  appId: '6b762fea-cf9a-42d7-89bb-dc035e661242',
  clientKey: 'cYAOn81sKGNSiGznLUqKAuI3Vkij7YQGHH4pb5AS',
  projectId: '08c8ab8a-eae7-47cb-96d5-aadf088a358b',
});

const RainbowProvider = ({ children }: React.HTMLProps<HTMLBaseElement>) => {
  const { chains, publicClient } = configureChains(
    [zkSync, mainnet, manta, linea],
    [publicProvider()],
  );

  const appName = 'ZeroLend Airdrop';
  const projectId = 'cfc12c6c49d329681212a698c47c01ef';

  const connectors = connectorsForWallets([
    {
      groupName: 'Recommended',
      wallets: [
        injectedWallet({ chains }),
        metaMaskWallet({ chains, projectId }),
        rainbowWallet({ projectId, chains }),
        coinbaseWallet({ appName, chains }),
        walletConnectWallet({ projectId, chains }),
        frameWallet({ chains }),
        rabbyWallet({ chains }),
        okxWallet({ chains, projectId }),
        ledgerWallet({
          projectId,
          chains,
        }),
        safeWallet({
          chains,
        }),
        phantomWallet({ chains }),
      ],
    },
    {
      groupName: 'Social Login',
      wallets: [
        particleWallet({ chains, authType: 'google' }),
        particleWallet({ chains, authType: 'facebook' }),
        particleWallet({ chains, authType: 'apple' }),
      ],
    },
  ]);

  const config = createConfig({
    autoConnect: true,
    connectors,
    publicClient,
  });

  return (
    <WagmiConfig config={config}>
      <Authentication chains={chains}>{children}</Authentication>
    </WagmiConfig>
  );
};

interface IAuthentication {
  children: React.ReactNode;
  chains: RainbowKitChain[];
}

const Authentication = (props: IAuthentication) => {
  const account = useGetAccount();
  const walletSignedIn = useUserAuthenticated(account || '');

  // https://www.rainbowkit.com/docs/custom-authentication
  const AUTHENTICATION_STATUS = walletSignedIn
    ? 'authenticated'
    : 'unauthenticated';
  return (
    <RainbowKitAuthenticationProvider
      adapter={authenticationAdapter}
      status={AUTHENTICATION_STATUS}
    >
      <RainbowKitProvider
        chains={props.chains}
        showRecentTransactions={true}
        coolMode={true}
        theme={myCustomTheme}
      >
        {props.children}
      </RainbowKitProvider>
    </RainbowKitAuthenticationProvider>
  );
};

export function Providers({ children }: { children: ReactNode }) {
  return (
    <Provider store={store}>
      <RainbowProvider>
        <ChakraProvider theme={customTheme} toastOptions={ToastOptions}>
          {children}
        </ChakraProvider>
      </RainbowProvider>
    </Provider>
  );
}

export default function App() {
  return (
    <Providers>
      <Router>
        {/*<Updaters />*/}
        <Navigation />
        <CaptchaModal />
      </Router>
    </Providers>
  );
}
