import store from '../store';
import { toggleCaptchaModal } from '../store/app/actions';
import Button, { ICButtonProps } from './Button';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { process_env } from '../env';

const ConnectWalletWithCaptcha = (props: ICButtonProps) => {
  const { openConnectModal } = useConnectModal();

  if (process_env.REACT_APP_ENABLE_CAPTCHA) {
    return (
      <Button onClick={() => store.dispatch(toggleCaptchaModal())} {...props}>
        Connect Wallet
      </Button>
    );
  } else {
    return (
      <Button onClick={openConnectModal} {...props}>
        Connect Wallet
      </Button>
    );
  }
};

export default ConnectWalletWithCaptcha;
