import { useSelector } from 'react-redux';
import { AppState } from '..';
import { ILeaderboardState } from '../../utils/inteface';
import { IUser } from './reducer';
import { useAccount } from 'wagmi';

export function useUserAuthenticated(account: string) {
  return useSelector(
    (state: AppState) =>
      account === state.app.user?.walletAddress?.toLowerCase() &&
      state.app.user?.jwt !== undefined,
  );
}

export function useGetUsersJwt() {
  return useSelector((state: AppState) => state.app.user?.jwt || undefined);
}

export function useGetUserAddress() {
  return useSelector(
    (state: AppState) => state.app.user?.walletAddress || undefined,
  );
}

export function useGetUser(): IUser | undefined {
  const wallet = useAccount(); // make sure that wallet is connected
  const user = useSelector((state: AppState) => state.app.user);
  return !!wallet.address ? user : undefined;
}

export function useGetLeaderboard(): ILeaderboardState[] {
  return useSelector((state: AppState) => state.app.leaderBoardData.data);
}

export function useGetCaptchaModalStatus(): boolean {
  return useSelector((state: AppState) => state.app.isCaptchaOpen);
}
