import {Box, Text} from '@chakra-ui/react';
import Countdown from 'react-countdown';

export const tge_start_date = new Date('May 6, 2024 7:30:00 UTC').getTime();

interface IProps {
  onComplete: (bool: boolean) => void;
}

const TGECountdown = (props: IProps) => {
  return (
    <Box pb={{base: 8, md: 16}}>
      <Text
        fontWeight={600}
        fontSize={{base: 24, md: 32}}
        className={'black-gradient-text'}
        textAlign={'center'}
        mb={{base: 4, md: 8}}
      >
        Claiming Opens In
      </Text>
      <Countdown
        date={tge_start_date}
        onComplete={() => props.onComplete(true)}
        renderer={({days, hours, minutes, seconds}) => {
          return (
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Box textAlign={'center'}>
                <Box
                  bg={'#FFFFFF20'}
                  p={{base: 1, md: 2}}
                  px={{base: 1, md: 3}}
                  textAlign={'center'}
                  borderRadius={{base: '8px', md: '12px'}}
                  w={{base: '50px', md: '100px'}}
                >
                  <Text
                    fontWeight={600}
                    className={'black-gradient-text'}
                    fontSize={{base: 24, md: 54}}
                  >
                    {days < 10 ? `0${days}` : `${days}`}
                  </Text>
                </Box>
                <Text
                  mt={2}
                  fontWeight={600}
                  fontSize={{base: 14, md: 24}}
                  className={'black-gradient-text'}
                >
                  Days
                </Text>
              </Box>
              <Text
                fontWeight={600}
                fontSize={42}
                className={'black-gradient-text'}
                mb={12}
                mx={4}
              >
                :
              </Text>
              <Box textAlign={'center'}>
                <Box
                  bg={'#FFFFFF20'}
                  p={{base: 1, md: 2}}
                  px={{base: 1, md: 3}}
                  textAlign={'center'}
                  borderRadius={{base: '8px', md: '12px'}}
                  w={{base: '50px', md: '100px'}}
                >
                  <Text
                    fontWeight={600}
                    className={'black-gradient-text'}
                    fontSize={{base: 24, md: 54}}
                  >
                    {hours < 10 ? `0${hours}` : `${hours}`}
                  </Text>
                </Box>
                <Text
                  mt={2}
                  fontWeight={600}
                  fontSize={{base: 14, md: 24}}
                  className={'black-gradient-text'}
                >
                  Hours
                </Text>
              </Box>
              <Text
                fontWeight={600}
                fontSize={42}
                className={'black-gradient-text'}
                mb={12}
                mx={4}
              >
                :
              </Text>
              <Box textAlign={'center'}>
                <Box
                  bg={'#FFFFFF20'}
                  p={{base: 1, md: 2}}
                  px={{base: 1, md: 3}}
                  textAlign={'center'}
                  borderRadius={{base: '8px', md: '12px'}}
                  w={{base: '50px', md: '100px'}}
                >
                  <Text
                    fontWeight={600}
                    className={'black-gradient-text'}
                    fontSize={{base: 24, md: 54}}
                  >
                    {minutes < 10 ? `0${minutes}` : `${minutes}`}
                  </Text>
                </Box>
                <Text
                  mt={2}
                  fontWeight={600}
                  fontSize={{base: 14, md: 24}}
                  className={'black-gradient-text'}
                >
                  Minutes
                </Text>
              </Box>
              <Text
                fontWeight={600}
                fontSize={42}
                className={'black-gradient-text'}
                mb={12}
                mx={4}
              >
                :
              </Text>
              <Box textAlign={'center'}>
                <Box
                  bg={'#FFFFFF20'}
                  p={{base: 1, md: 2}}
                  px={{base: 1, md: 3}}
                  textAlign={'center'}
                  borderRadius={{base: '8px', md: '12px'}}
                  w={{base: '50px', md: '100px'}}
                >
                  <Text
                    fontWeight={600}
                    className={'black-gradient-text'}
                    fontSize={{base: 24, md: 54}}
                  >
                    {seconds < 10 ? `0${seconds}` : `${seconds}`}
                  </Text>
                </Box>
                <Text
                  mt={2}
                  fontWeight={600}
                  fontSize={{base: 14, md: 24}}
                  className={'black-gradient-text'}
                >
                  Seconds
                </Text>
              </Box>
            </Box>
          );
        }}
      />
    </Box>
  );
};

export default TGECountdown;
