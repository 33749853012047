import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import LeaderboardPage from '../pages/leaderboard.page';
import { useEffect } from 'react';
import TaskPage from '../pages/task.page';
import HomePage from '../pages/index.page';
import DiscordVerifyPage from '../pages/discord-verify.page';
import TwitterVerifyPage from '../pages/twitter-verify.page';

export const Navigation = () => {
  const location = useLocation();
  const referred = new URLSearchParams(location.search).get('invite');

  // referral
  useEffect(() => {
    if (referred) localStorage.setItem('invite', referred);
  }, [referred]);

  return (
    <Routes>
      <Route path={'/'} element={<HomePage />} />
      <Route path={'*'} element={<HomePage />} />
      {/*<Route path={'/leaderboard'} element={<LeaderboardPage />} />
      <Route
        path={'/tasks'}
        element={<Navigate to={'/voyage-1/chapter-1/quests'} />}
      ></Route>
      <Route path={'/voyage-1/chapter-1/quests'} element={<TaskPage />} />
      <Route path={'/discord/callback'} element={<DiscordVerifyPage />} />
      <Route path={'/twitter/callback'} element={<TwitterVerifyPage />} />*/}
    </Routes>
  );
};

export default Navigation;
